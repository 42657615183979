@use "mixins" as *;
.modal__layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--transparent-bg-color);
    z-index: 999;

    &.-hidden {
        display: none;
    }
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: var(--content-bg--color-lighter);
    border: .6px solid var(--color-border-primary);    
    padding: 1rem;
    border-radius: var(--radius);
    gap: 1rem;
    max-height: 80%;    
    z-index: 999;
}

.modal__quit {
    padding: .2rem;
}

.modal__quit-icon {
    width: 1.6rem;
    height: 1.6rem;
    color: var(--color-icon);
}

.modal__quit:hover .modal__quit-icon {
    color: var(--color-icon-focus);
}

.modal__content {
    overflow-y: auto;
}
